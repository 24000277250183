<template>
  <div>
    <section-banner :model="section"></section-banner>
    <v-container>
      <base-loader :isLoading="isLoading"></base-loader>
    </v-container>
  </div>
</template>

<script>
import SectionBanner from "@/components/Frontend/SectionBanner";
import { gql } from "apollo-boost";
import { mapState } from "vuex";
export default {
  components: {
    SectionBanner,
  },
  data() {
    return {
      isLoading: false,
      section: {
        Name: "",
        Title: "Order Completed",
        Subtitle: "Your order was completed successfully",
        HTMLDescription: `<h3 class="mar30top">Order number: ${this.$route.params.order_id}</h3>
          <h4 class="mar30top">View your purchase on your dashboard <a class="download__link" href="/user/purchases"> <strong>HERE</strong> </a></h4>`,
        img: require("@/assets/img/background.jpg"),
      },
      order: {
        id: "",
      },
    };
  },
  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,
    order: {
      query: gql`
        query order($id: ID!) {
          order(id: $id) {
            id
            totalAmount
            products
            payment
          }
        }
      `,
      variables() {
        const self = this;
        return {
          id: self.$route.params.order_id,
        };
      },
      result() {
        this.updateOrder();
      },
    },
  },
  methods: {
    async updateOrder() {
      const self = this;
      self.isLoading = true;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation updateOrder($order: OrderUpdateInput!) {
              updateOrder(order: $order) {
                id
              }
            }
          `,
          variables: {
            order: {
              id: self.$route.params.order_id,
              payment: "complete",
            },
          },
        });

        if (response) {
          self.isLoading = false;
        }
      } catch (e) {
        self.$swal({
          title: "Error",
          text: "Unable to process order now, please try again later.",
          icon: "error",
          confirmButtonColor: "#c79477",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
